import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { HttpHelperService } from '@services/http-helper/http-helper.service';
import { DashboardData } from '../models/DashboardData';
import { TenantHelperService } from '@services/tenant-helper/tenant-helper.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl = `${environment.apiUrl}v1/DemoPharmacy/`;

  private http = inject(HttpHelperService);

  private tenantHelpers = inject(TenantHelperService);

  private tenantId = '';

  constructor() {
    this.tenantHelpers.tenant.pipe(takeUntilDestroyed()).subscribe({
      next: (tenant) => {
        this.tenantId = tenant.tenant;
      }
    });
  }

  public RetrieveData() {
    return this.http.get<DashboardData>(`${this.apiUrl}Dashboard/${this.tenantId}/28`);
  }
}
