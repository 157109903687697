import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalTrackingComponent } from '../global-tracking/global-tracking.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [GlobalTrackingComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent {}
