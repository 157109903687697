import { DataLabelsOptions, PointLabelObject, Tooltip, TooltipFormatterContextObject } from 'highcharts';
import { currencyFormatter, pctFormatter } from './formatters';

export function commonToolTipFormatter(
  this: TooltipFormatterContextObject,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tooltip: Tooltip
): false | string | Array<string | null | undefined> | null | undefined {
  //<span style="color: #000000">${this.point.name}:</span>
  return `<b style="color: #000000">${currencyFormatter.format(this.point.y!)}</b>`;
}

export function commonDataPointFormatter(
  this: PointLabelObject,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options: DataLabelsOptions
): number | string | null | undefined {
  const total = this.point.series.data.reduce((pv, cv) => pv! + cv.y!, 0);
  const pct = (this.point.y! / total) * 100.0;
  //return `<span class='pieLabelStyle'>${currencyFormatter.format(this.point.y!)}</span><br /><span class='pieLabelStyle'>(${pctFormatter.format(pct)}%)</span>`;
  return `<span class='pieLabelStyle'>${this.point.name}</span><br /><span class='pieLabelStyle'>${pctFormatter.format(pct)}%</span>`;
}
