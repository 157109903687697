/// <reference types="@angular/localize" />
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { environment } from '@env/environment';

import { AppComponent } from './app/app.component';
import { appConfig } from 'app/app.config';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-047503 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Lumatrak Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Lumatrak )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Lumatrak )_need_to_be_licensed___( Lumatrak )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 September 2024 )____[v2]_MTcyNTY2MzYwMDAwMA==9a58a8418ea72a9b62b6e3ec32a9a232'
);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
