import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayRoundedLargeAmountDescription',
  standalone: true
})
export class DisplayRoundedLargeAmountDescriptionPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'number') {
      if (value > 999999) {
        return 'BN';
      } else if (value > 999) {
        return 'M';
      } else if (value > 0) {
        return 'T';
      } else {
        return '';
      }
    } else {
      return value;
    }
  }
}
