import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayRoundedLargeAmount',
  standalone: true
})
export class DisplayRoundedLargeAmountPipe implements PipeTransform {
  private singleDecimalFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });

  private noDecimalFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: number, ...args: unknown[]): string {
    console.info('pipe', value);
    if (value > 9999999) {
      // Multi digit Billions
      return this.noDecimalFormatter.format(value / 1000000);
    } else if (value > 999999) {
      // Single digit Billions
      return this.singleDecimalFormatter.format(value / 1000000);
    } else if (value > 9999) {
      // Multi digit Millions
      return this.noDecimalFormatter.format(value / 1000);
    } else if (value > 999) {
      // Single digit Millions
      return this.singleDecimalFormatter.format(value / 1000);
    } else {
      return value.toString();
    }
  }
}
