import { Component, effect, input } from '@angular/core';

/** High Charts Related modules - BEGIN */
import { HighchartsChartModule } from 'highcharts-angular';
import HighchartsESM from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/masters/modules/accessibility.src';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import 'highcharts/es-modules/masters/modules/exporting.src';
import 'highcharts/es-modules/masters/modules/no-data-to-display.src';
/** High Charts Related modules - END */

import { BarChartDataBuilderModel } from '@models/BarChartDataBuilderModel';
import { StatusDivisionFacilityChartRecord } from 'app/components/dashboard/models/StatusDivisionFacilityChartRecord';
import { ExtendedChart } from '@models/ExtendedChart';

/** 2024.02.20 - JAL - Workaround for missing property on this object (https://github.com/highcharts/highcharts/issues/20673) */
declare module 'highcharts' {
  interface PlotSeriesDataLabelsOptions {
    distance?: number;
  }
}

@Component({
  selector: 'app-actual-savings-ytd-stacked-bar',
  standalone: true,
  templateUrl: './actual-savings-ytd-stacked-bar.component.html',
  styleUrl: './actual-savings-ytd-stacked-bar.component.scss',
  imports: [HighchartsChartModule]
})
export class ActualSavingsYtdStackedBarComponent {
  rawData = input.required<StatusDivisionFacilityChartRecord[]>();

  colorMap = input.required<Record<string, number>>();

  /** Required by HighCharts */
  Highcharts: typeof HighchartsESM = HighchartsESM;

  private chart?: ExtendedChart;

  public setChart(chart: Highcharts.Chart) {
    this.chart = chart as ExtendedChart;
  }

  private categories: string[] = [];

  private childLayers = new Map<string, Highcharts.SeriesBarOptions[]>();

  /**
   * Data series used by the "Region" stacked bar chart
   * Region Chart:
   *  Layers:
   *    Region/Drug Status
   *    State/Drug Status
   *    Facility/Drug Status
   *    Location/Drug Status
   */
  private stackedBarSeriesData: Highcharts.SeriesBarOptions[] = [];

  /**
   * Drill down options used by the stacked bar chart
   */
  private drillDownOptions: Highcharts.DrilldownOptions = {
    allowPointDrilldown: true,
    series: []
  };

  /**
   * The options used to create the stacked bar 'By Region' chart
   */
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      reflow: true,
      styledMode: false,
      events: {
        drilldown: function (e) {
          console.info('in drilldown', e);
        },
        drillup: function (e) {
          console.info('in drillup', e);
        }
      }
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      },
      menuItemStyle: {
        display: 'none'
      }
    },
    title: {
      text: undefined
    },
    xAxis: {
      type: 'category',
      categories: []
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Actual Total Amount by Drug Status'
      }
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: {point.percentage:.2f}%<br/>',
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          format: '{point.y:.0f}'
        },
        point: {
          events: {
            click: this.pointClick.bind(this)
          }
        }
      }
    },
    series: this.stackedBarSeriesData,
    drilldown: this.drillDownOptions
  };

  private pointClick(e: Highcharts.PointClickEventObject) {
    console.info('in pointClick', e);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = (e.point as any).drilldown;
    if (this.childLayers.has(key)) {
      const childNodes = this.childLayers.get(key)!;
      const categories = childNodes[0].data!.map((dn) => (dn as Highcharts.PointOptionsObject).name!);

      this.chart!.xAxis[0].setCategories(categories);
      childNodes.forEach((v) => {
        this.chart!.addSingleSeriesAsDrilldown(e.point, v);
      });
      this.chart!.applyDrilldown();
    }

    //chart.xAxis[0].setCategories()
  }

  constructor() {
    effect(() => {
      if (this.rawData()) {
        this.generateChartData(this.rawData());
      }
    });
  }

  public makeFullScreen() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.chart!.fullscreen.toggle();
  }

  /**
   * This data comes in in records that have a Status, a Division, a Facility, and a Cost.
   * We need to have the following series:
   *  Division => Status (Cost)
   *  Facility => Status (Cost)
   */
  private generateChartData(records: StatusDivisionFacilityChartRecord[]) {
    console.time('chartdata');

    // Start building the bar data structure to store the data by Region/State/Facility/Location
    const parentNode = this.createBarChartDataModel(null, -1, '', '');

    const distinctSeries = new Set<string>();

    // Process all of the rows to get the raw data
    // The categories are:
    //    Division
    //      => Facility
    // The Series will always be the different statuses
    records.forEach((x) => {
      if (!distinctSeries.has(x.status)) {
        distinctSeries.add(x.status);
      }

      let divisionIdx = 0;
      if (parentNode.childIndexes.has(x.division)) {
        divisionIdx = parentNode.childIndexes.get(x.division)!;
      } else {
        divisionIdx = parentNode.children.length;
        parentNode.childIndexes.set(x.division, divisionIdx);
        parentNode.children.push(this.createBarChartDataModel(parentNode, 0, x.division, '|REG_'));
      }

      // Add division
      const divisionNode = parentNode.children[divisionIdx];
      let total = x.cost;
      if (divisionNode.totalAmounts.has(x.status)) {
        total += divisionNode.totalAmounts.get(x.status)!;
      }
      divisionNode.totalAmounts.set(x.status, total);

      let facilityIdx = 0;
      if (divisionNode.childIndexes.has(x.facility)) {
        facilityIdx = divisionNode.childIndexes.get(x.facility)!;
      } else {
        facilityIdx = divisionNode.children.length;
        divisionNode.childIndexes.set(x.facility, facilityIdx);
        divisionNode.children.push(this.createBarChartDataModel(divisionNode, 1, x.facility, ''));
      }

      const facilityNode = divisionNode.children[facilityIdx];
      facilityNode.totalAmounts.set(x.status, x.cost);
    });

    // Remove all of the data from the bar chart
    if (this.chartOptions.series!.length! > 0) {
      this.chartOptions.series!.splice(0, this.chartOptions.series!.length);
    }
    if (this.chartOptions.drilldown!.series!.length! > 0) {
      this.chartOptions.drilldown!.series!.splice(0, this.chartOptions.drilldown?.series?.length);
    }

    // Build the series for the bar chart
    const seriesData = this.buildBarSeries(parentNode, Array.from(distinctSeries), '');

    // Build the series to use for the initial chart
    this.categories = parentNode.children.map((x) => x.name);

    // Assign the values to stackedBarSeriesData HERE because if we don't then the chart
    // will be blank when we first try to render it.
    seriesData.forEach((v) => {
      this.stackedBarSeriesData.push(v);
    });

    if (this.chart !== undefined) {
      // Remove all of the current series
      let x = this.chart.series.length - 1;
      for (; x >= 0; x--) {
        this.chart.series[x].remove();
      }
      seriesData.forEach((v) => {
        this.chart!.addSeries(v);
      });

      this.chart!.options.drilldown!.series = this.chartOptions.drilldown?.series;

      this.chart.xAxis[0].setCategories(this.categories, true);
    }

    console.timeEnd('chartdata');
  }

  private buildBarSeries(
    parentNode: BarChartDataBuilderModel,
    distinctSeries: string[],
    id: string
  ): Highcharts.SeriesBarOptions[] {
    // Contains JUST the values that fall into the 'distinctSeries' values in the
    // children of the parent node
    const topLevelSeries = new Map<string, Highcharts.SeriesBarOptions>();

    // Create the data for the distinct series that we KNOW that we have
    /*
        [{
          type: 'column,
          name: 'Relocated',
          data: [{
            name 'Mountain',
            y: 10838,
            id: '',
            drilldown: '|REG_Mountain'
          },
          {
            name: 'South',
            y: 248900,
            id: '',
            drilldown: 'REG_South'
          }],
          className: '',
          id: '',
        },
        {
          type: 'column,
          name: 'Returned',
          data: [<array 5>],
          className: '',
          id: ''
        }]
    */
    distinctSeries.forEach((x) => {
      const seriesData: Highcharts.SeriesBarOptions = {
        type: 'bar',
        name: x,
        data: [],
        className: this.getSeriesClass(x),
        id: id
      };
      topLevelSeries.set(x, seriesData);
    });

    /**
     * Iterate through every child object on the parent.
     * Go through each child object's totals and add it to the correct series
     */
    parentNode.children.forEach((node) => {
      const drillDownId = id + node.childLayerSuffix + node.name;

      console.info('------------------------------------------------------------------------------');
      console.info(
        `building series data for ${node.name} at layer ${node.depth} with id of ${id} and drilldownid of ${drillDownId}`
      );

      distinctSeries.forEach((k) => {
        // Get the value to add to this series
        const val = node.totalAmounts.has(k) ? node.totalAmounts.get(k)! : 0;

        topLevelSeries.get(k)?.data?.push({
          name: node.name,
          y: val,
          id: id,
          drilldown: node.children.length === 0 ? undefined : drillDownId
        });
      });

      // Create drilldown information for THIS child node
      const drillDownSet = this.buildBarSeries(node, distinctSeries, drillDownId);
      console.info('drillDownset', drillDownSet);

      //drillDownSet.forEach((s) => {
      //  this.chartOptions.drilldown!.series?.push({
      //    type: 'bar',
      //    name: s.name,
      //    id: drillDownId,
      //    data: s.data
      //  })
      //});
      this.childLayers.set(drillDownId, [...drillDownSet.values()]);
    });

    let hasValue = false;
    topLevelSeries.forEach((x) => {
      if (x.data && x.data.length > 0) {
        hasValue = true;
      }
    });

    console.info(`full set`, topLevelSeries);
    if (hasValue) {
      return [...topLevelSeries.values()];
    }
    return [];
  }

  /**
   * Generate a new BarChartDataBuilderModel object with default values where appropriate
   * @param depth The depth of this layer
   * @param name The friendly name to assign to this object
   * @param childPrefix The suffix to use when building drill down data
   * @returns An object with the default state for a new BarChartDataBuilderModel
   */
  private createBarChartDataModel(
    parent: BarChartDataBuilderModel | null,
    depth: number,
    name: string,
    childPrefix: string
  ): BarChartDataBuilderModel {
    return {
      depth: depth,
      name: name,
      categories: new Set<string>(),
      totalAmounts: new Map<string, number>(),
      children: [],
      childIndexes: new Map<string, number>(),
      parentObject: parent,
      childLayerSuffix: childPrefix
    };
  }

  /**
   * Gets the CSS class name to use for a given series or data point based on its name
   * @param name The name of the series or data point that needs its cssClass
   * @returns The css class to use for the given series or data point
   */
  private getSeriesClass(name: string) {
    switch (name) {
      case 'Returned':
        return 'greenCell';

      case 'Relocated':
        return 'orangeCell';

      default:
        return '';
    }
  }
}
