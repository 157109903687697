@if (showPie()) {
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="pieChartOptions"
    class="highcharts-light block w-12 h-12"
    (chartInstance)="setPieChart($event)">
</highcharts-chart>
}
@else {
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="barChartOptions"
    class="highcharts-light block w-12 h-12"
    (chartInstance)="setBarChart($event)">
</highcharts-chart>
}
