import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  AdaptableAngularAgGridModule,
  AdaptableApi,
  AdaptableOptions,
  AdaptableReadyInfo
} from '@adaptabletools/adaptable-angular-aggrid';

import { AgGridModule } from '@ag-grid-community/angular';
import { ColDef, GridOptions, Module, ValueFormatterParams } from '@ag-grid-community/core';
import { defaultGridModules } from '@aggrid/defaultModules';
import { defaultColDef } from '@aggrid/defaultColDef';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';

import { PharmacyDemoService } from '@services/pharmacy-demo/pharmacy-demo.service';
import { GlobalRecord } from '@services/pharmacy-demo/models/GlobalRecord';

import { defaultExcelExportStyles } from '@aggrid/defaultExcelExportStyles';
import { dateValueFormatter } from '@aggrid/ValueFormatters/dateTimeValueFormatter';

import { LayoutService } from '@services/layout/layout.service';
import { ILayoutGroup, LayoutGroup } from '@models/layout-group';
import { LayoutManagerComponent } from '../layout-manager/layout-manager.component';
import { adaptableLicense } from 'app/constants/licenseKeys';
import { cellClassRuleHelperGlobalRecord, currencyFormatter } from 'app/constants/formatters';
import { allGridLayouts, PulseGridId } from 'app/constants/adaptableLayouts';
import { LayoutGroupName } from '@enums/layout-group-name.enum';
import { DrillDownBarChartComponent } from '../charts/drill-down-bar-chart/drill-down-bar-chart.component';
import { ExpirationRiskColorMap } from 'app/constants/chartColorMap';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-global-tracking',
  standalone: true,
  imports: [
    AgGridModule,
    AdaptableAngularAgGridModule,
    ButtonModule,
    CardModule,
    DrillDownBarChartComponent,
    DropdownModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    FormsModule,
    LayoutManagerComponent,
    InputTextModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule
  ],
  templateUrl: './global-tracking.component.html',
  styleUrl: './global-tracking.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalTrackingComponent implements OnInit {
  /** Functions to retrieve pharmacy data */
  private dataService = inject(PharmacyDemoService);

  private confirmationService = inject(ConfirmationService);

  private messageService = inject(MessageService);

  private layoutService = inject(LayoutService);

  availableLayouts = signal<string[]>([]);

  layoutSelectionForm = new FormControl('');

  ExpirationRiskColorMap = ExpirationRiskColorMap;

  currentLayoutGroup: ILayoutGroup = new LayoutGroup();

  isAddingNewLayout = signal(false);

  newLayoutName = new FormControl('');

  currentLayoutName = signal('');

  private adaptableApi: AdaptableApi | null = null;

  /** Modules to load into the ag-grid instance on this page */
  public agGridModules: Module[] = defaultGridModules;

  private popupParent: HTMLElement | null = document.body;

  /** Data that was retrieved from the API */
  rowData = signal<GlobalRecord[]>([]);

  /** The column definitions to use on this page */
  private gridCols: ColDef<GlobalRecord>[] = [
    {
      field: 'id',
      headerName: 'ID',
      cellClass: 'ag-right-aligned-cell',
      type: 'abColDefNumber',
      hide: true
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      cellRenderer: 'agGroupCellRenderer',
      cellClassRules: cellClassRuleHelperGlobalRecord,
      type: 'abColDefString'
    },
    {
      field: 'ndc',
      headerName: 'NDC',
      width: 120,
      type: 'abColDefString'
    },
    {
      field: 'gname',
      headerName: 'Drug Name',
      width: 110,
      type: 'abColDefString'
    },
    {
      field: 'gtin',
      headerName: 'GTIN',
      width: 135,
      type: 'abColDefString'
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      width: 115,
      cellDataType: 'date',
      enableRowGroup: false,
      chartDataType: 'time',
      type: 'abColDefDate',
      valueFormatter: dateValueFormatter
    },
    {
      field: 'returnBy',
      headerName: 'Return By',
      width: 120,
      cellDataType: 'date',
      enableRowGroup: false,
      type: 'abColDefDate',
      valueFormatter: dateValueFormatter
    },
    {
      field: 'daysUnused',
      headerName: 'Days Unused',
      width: 125,
      cellClass: 'ag-right-aligned-cell',
      type: 'abColDefNumber'
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      width: 100,
      cellClass: 'ag-right-aligned-cell',
      type: 'abColDefNumber'
    },
    {
      field: 'manu',
      headerName: 'Manufacturer',
      width: 160,
      type: 'abColDefString'
    },
    {
      field: 'dist',
      headerName: 'Distributor',
      width: 145,
      type: 'abColDefString'
    },
    {
      field: 'lot',
      headerName: 'Lot #',
      width: 95,
      type: 'abColDefString'
    },
    {
      field: 'division',
      headerName: 'Division',
      width: 125,
      type: 'abColDefString'
    },
    {
      field: 'facility',
      headerName: 'Facility',
      width: 125,
      type: 'abColDefString'
    },
    {
      field: 'location',
      headerName: 'ADC /Bin',
      width: 105,
      type: 'abColDefString'
    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 105,
      cellClass: 'ag-right-aligned-cell',
      valueFormatter: (params: ValueFormatterParams<GlobalRecord, number>) => {
        if (params.value) {
          return currencyFormatter.format(params.value!);
        }
        return '';
      },
      type: 'abColDefNumber'
    },
    {
      field: 'extendedCost',
      headerName: 'Ext Cost',
      width: 105,
      cellClass: 'ag-right-aligned-cell',
      valueFormatter: (params: ValueFormatterParams<GlobalRecord, number>) => {
        if (params.value) {
          return currencyFormatter.format(params.value!);
        }
        return '';
      },
      type: 'abColDefNumber'
    }
  ];

  public gridOptions: GridOptions<GlobalRecord> = {
    defaultColDef: defaultColDef,
    columnDefs: this.gridCols,
    loading: true,
    columnMenu: 'new',
    defaultExcelExportParams: {
      allColumns: false,
      author: 'Lumatrak',
      sheetName: 'Overview',
      fileName: `PharmacyOverview_${new Date().toISOString().substring(0, 10)}.xlsx`
    },
    enableCellTextSelection: true,
    enableRangeSelection: true,
    excelStyles: defaultExcelExportStyles,
    groupDisplayType: 'groupRows',
    popupParent: this.popupParent,
    rowGroupPanelShow: 'always',
    rowSelection: 'single',
    showOpenedGroup: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
      ]
    },
    suppressCsvExport: true,
    suppressMenuHide: true
  };

  public adaptableOptions: AdaptableOptions<GlobalRecord> = {
    licenseKey: adaptableLicense,
    primaryKey: 'id',
    adaptableId: PulseGridId,
    userInterfaceOptions: {
      showLoadingScreen: true,
      showAdapTableVersion: false,
      showDocumentationLinks: false
    },
    predefinedConfig: {
      Dashboard: {
        IsHidden: true,
        IsFloating: false,
        ModuleButtons: []
      },
      Layout: {
        Layouts: allGridLayouts
      }
    },
    layoutOptions: {
      autoSaveLayouts: false
    },
    exportOptions: {
      appendFileTimestamp: true,
      exportDateFormat: 'yyyy-MM-dd',
      exportFormatType: 'formattedValue'
    },
    columnFilterOptions: {
      quickFilterOptions: {
        quickFilterTrigger: 'mouseenter'
      }
    }
  };

  isChartShowing: boolean = false;

  constructor() {
    this.layoutService.activeGroup$.pipe(takeUntilDestroyed()).subscribe((activeGroup) => {
      this.currentLayoutGroup = activeGroup;
      this.currentLayoutName.set(this.currentLayoutGroup.default);
      console.log('Active group changed:', this.currentLayoutGroup);
      if (this.adaptableApi) {
        this.adaptableApi.layoutApi.setLayout(`${this.currentLayoutGroup.name} - ${this.currentLayoutGroup.default}`);
      }
    });
  }

  ngOnInit(): void {
    this.dataService.GlobalRecords().subscribe({
      next: (data) => {
        data.map((x) => (x.extendedCost = x.cost * x.quantity));
        this.rowData.set(data);
        this.adaptableApi?.agGridApi.setGridOption('loading', false);
      },
      error: () => {
        alert('Unable to retrieve data. Please refresh the page to try again.');
      }
    });
  }

  adaptableReady(event: AdaptableReadyInfo) {
    console.info(`in adaptableReady : ${event.userName}`);
    //event.adaptableApi.settingsPanelApi.openSettingsPanel('GridInfo');
    this.adaptableApi = event.adaptableApi; //event.adaptableApi.layoutApi.setLayout('');
    this.layoutService.setActiveGroup(LayoutGroupName.GlobalTracking);
    //this.layoutSelectionForm.setValue(this.currentLayoutGroup.default);
  }

  /**
   * Loads all known layouts into a dropdown for user selection
   * @param defaultLayout Default layout to load. Set to null or a non existing layout to
   * load the first layout found.
   */
  // loadKnownLayouts(defaultLayout: string | null) {
  //   if (this.adaptableApi) {
  //     console.info('loading layouts');
  //     this.availableLayouts.set(this.adaptableApi.layoutApi.getLayouts().map((x) => x.Name));

  //     if (
  //       defaultLayout !== null &&
  //       this.availableLayouts().some((x) => x.toLowerCase() === defaultLayout.toLowerCase())
  //     ) {
  //       //this.layoutSelectionForm.setValue(defaultLayout);
  //       //this.layoutSelectionForm.setValue()
  //     } else {
  //       this.layoutSelectionForm.setValue(this.availableLayouts()[0]);
  //     }
  //   }
  // }

  /**
   * Checks to see if there is an existing layout by name
   * @param name Name to check
   * @returns TRUE if the layout already exists of we don't have a reference to the adaptable API. FALSE
   * if we DO have a reference to the adaptable API AND no layout with the same name was found.
   */
  doesLayoutExist(name: string) {
    if (!this.adaptableApi) {
      return true;
    }

    return this.adaptableApi.layoutApi.getLayouts().some((x) => x.Name.toLowerCase() === name.toLowerCase());
  }

  changeLayout(layoutName: string) {
    if (this.adaptableApi) {
      this.adaptableApi.layoutApi.setLayout(`${this.currentLayoutGroup.name} - ${layoutName}`);
    }
  }

  /** Saves the CURRENT layout settings, overwriting prior settings for this layout. */
  updateLayout() {
    if (this.adaptableApi) {
      this.adaptableApi.layoutApi.saveCurrentLayout();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Layout has been updated' });
    }
  }

  /**
   * Saves the CURRENT layout as a BRAND NEW layout
   * @param name New name to use
   */
  // saveNewLayout(name: string) {
  //   if (this.adaptableApi) {
  //     // Does this layout already exist?
  //     if (this.adaptableApi.layoutApi.getLayouts().some((x) => x.Name.toLowerCase() === name.toLowerCase())) {
  //       alert('a layout with this name already exists');
  //     } else {
  //       const result = this.adaptableApi.layoutApi.cloneLayout(this.adaptableApi.layoutApi.getCurrentLayout(), name);
  //       if (result) {
  //         //this.loadKnownLayouts(name);
  //       }
  //     }
  //   }
  // }

  // saveLayoutWrapper() {
  //   console.info('saving new layout');
  //   this.saveNewLayout('Global - hey now');
  // }

  deleteCurrentLayout() {
    if (this.adaptableApi) {
      const currentLayout = this.layoutSelectionForm.getRawValue()!;
      this.deleteLayout(currentLayout);
      //this.loadKnownLayouts(null);
    }
  }

  deleteLayout(name: string) {
    if (this.adaptableApi) {
      if (this.doesLayoutExist(name)) {
        this.adaptableApi.layoutApi.deleteLayoutByName(name);
      }
    }
  }

  confirmReset(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to reset the grid?',
      header: 'Reset Grid',
      icon: 'fa-duotone fa-solid fa-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Grid has been reset' });
        this.resetGrid();
      },
      reject: () => {
        //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  resetGrid() {
    if (this.adaptableApi) {
      this.adaptableApi.configApi.reloadPredefinedConfig();
      this.layoutService.resetGroups(LayoutGroupName.GlobalTracking);
    }
  }

  saveNewLayout() {
    if (this.newLayoutName.value === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Layout name cannot be empty',
        life: 3000
      });

      return;
    } else {
      const layoutName = `${this.currentLayoutGroup.name} - ${this.newLayoutName.value}`;
      this.isAddingNewLayout.set(false);

      if (this.adaptableApi) {
        if (this.doesLayoutExist(layoutName)) {
          console.log('A layout with this name already exists');
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'A layout with this name already exists',
            life: 3000
          });

          this.newLayoutName.setValue('');
        } else {
          const clonedResult = this.adaptableApi.layoutApi.cloneAndSetLayout(
            this.adaptableApi.layoutApi.getCurrentLayout(),
            layoutName
          );

          if (clonedResult) {
            this.currentLayoutName.set(this.newLayoutName.value!);
            this.layoutService.addLayout(this.newLayoutName.value!);
          }
        }
      }
    }
  }

  exportGrid() {
    if (this.adaptableApi) {
      this.adaptableApi.exportApi.exportVisualDataToExcel();
    }
  }
}
